<template>
  <div class="text-center mt-5">
    <br>
      <center><img  src="img/logo.png"   style="width: 70px; "></center>
     <br><br> 
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-4 col-10 mt-5">
           <p style="color: #fff; font-size:20px;">Total registros en este dispositivo:  {{arrayDat.length}}
           <br>    
           <div class="mt-3">
               <v-btn @click="descargaReporte()" block style="color: #266BC3; text-transform: capitalize;">  Cargar registros al administrador <b-spinner small v-if="loader"></b-spinner></v-btn>
            </div>  
            <div class="mt-4">         
              <p style="color: #fff; font-size:14px;">    Asegúrate de estar conectado a Internet antes de cargar tus registros.</p>
            </div>     
          </div>
      </div>
  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
  export default {
    data () {
      return { 
        loader:false,
        arrayDat:[]
      }
    },
    methods:{
        descargaReporte(){
            this.loader  = true;
          axios.post('https://apinba.esmarketing.mx/api/registro/subir',{
                    'data' : this.arrayDat
                }).then(response => {
                    console.log(response);          
                    Swal.fire(
                    "List@",
                    "Se han enviado exitosamente tus registros.",
                    "success"
                    )                    
                    this.arrayDat = [];
                    this.loader = false;
                    this.actualiza();
                }).catch(function (error) {
                    console.log(error);
                }); 
        },
        actualiza(){
            var url= 'http://apiqr.test/api/registro/actualiza';
                  axios.get(url).then(
                        ({data}) => {
                         console.log(data);
                         this.listarDatos();
                        }
                    ); 
        },
        listarDatos(){
            var url= 'http://apiqr.test/api/registro/traer';
                  axios.get(url).then(
                        ({data}) => {
                         this.arrayDat = data.registros; 
                        }
                    );  
        }
    },
    mounted(){
        this.listarDatos(); 
    }
  }
</script> 
<style>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
</style>
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router'
import admin from './components/HelloWorld.vue'
import formulario from './components/formulario.vue'
import invitado from './components/invitado.vue'
import evento from './components/evento.vue'
import registro from './components/registro.vue'
import adminregistro from './components/adminregistro'
import subir from './components/subir'
import ForceDownload from "./utils/ForceDownload";
import bus from "./utils/bus";
import VueGeolocation from 'vue-browser-geolocation';
import detectMobile from "./utils/DetecMobile";

Vue.prototype.$bus = bus;
Vue.prototype.$mobile = detectMobile;
Vue.prototype.$fileDownload = ForceDownload;
Vue.config.productionTip = false

Vue.prototype.$getDate = () => {

  let d = new Date();
  let a = d.getFullYear();
  let m = d.getMonth()+1;
  let dy = d.getDate();

  return `${a}-${m}-${dy}`

}
Vue.prototype.$getDateHour = () => {

  let d = new Date();
  let a = d.getFullYear();
  let m = d.getMonth();
  let dy = d.getDate();
  let h = d.getHours()
  let mn = d.getMinutes()
  let ms = d.getMilliseconds()

  return `${a}-${m}-${dy} ${h}.${mn}.${ms}`

}

Vue.use(VueGeolocation);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);



var router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: formulario
    },
    {
      path: '/admin',
      component: admin
    },
    {
      path: '/evento',
      component: evento
    },
    {
      path: '/invitado/:id',
      component: invitado
    },
    {
      path: '/registro',
      component: registro
    },
    {
      path: '/admin-registros',
      component: adminregistro
    },
    {
      path: '/subir',
      component: subir
    },
  ]
});


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')

<template>
  <div>
    <br>
    <div class="row  justify-content-center">
      <div class="col-lg-1 col-11 col-md-10">
        <center><img src="img/logo.png" style="width: 80px; " ></center>
         
      </div>
      <div class="col-lg-5 col-11 col-md-10 mt-5">
          <v-card elevation="10"  >
            <v-card-text>
              <div class="row">
                <div class="col-md-4  text-center">
                  <b style="color: #266BC3;">Invitados al Evento </b>
                  <br>
                  <template v-if="isMobile">
                    <h3 class="text-danger mt-2">{{total}} / 750</h3>
                  </template>
                  <template v-else>
                    <h5 class="text-danger mt-2">{{total}} / 750</h5>
                  </template>                  
                </div>
                <div class="col-md-4 text-center">
                  <b style="color: #266BC3;">Fecha del Evento</b>
                  <br>
                 <template v-if="isMobile">
                    <h3 class="text-danger mt-2 ">08 de Junio del 2023</h3>
                  </template>
                  <template v-else>
                    <h5 class="text-danger mt-2 ">08 de Junio del 2023</h5>
                  </template>    
                  
                </div>
                <div class="col-md-4 text-center">
                  <!-- <b style="color: #266BC3;">Reporte Excel</b> -->
                  <br> 
                  <v-btn @click="descargaReporte()" block style="background: #1d428a; color: #fff; text-transform: capitalize;">  Descargar reporte <b-spinner small v-if="loader"></b-spinner></v-btn>

                </div>
              </div>
            </v-card-text>
          </v-card>
          <br>
          <div class="row justify-content-center">    
            <div class="col-md-12">
              <br>
              <v-card elevation="10"  >
                <v-card-text>
                     <b style="color: #266BC3;">Confirmación de Asistencia </b>
                     <br>
                        <div class="table-responsive">
                            <table class="table table-hover table-striped mt-3" style="">
                                <thead>
                                <tr>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Invitado</th>
                                    <th scope="col">Estatus</th>
                                </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="est in arrayEstado" :key="est.id">
                                    <td>{{est.created_at | fechaA}}</td>
                                    <td>Invitado {{est.invitado}}</td>
                                    <td>
                                      <b class="text-success">Registrado</b>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                </v-card-text>
              </v-card>
               <br><br>
            </div>   
          </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment  from 'moment';
  export default {
    name: 'administrador',
        data () {
          return {
            total:0,
            arrayEstado:[],
            isMobile: this.$mobile(),
            loader:false
          }
        },
        filters: {
            fechaA:function(value) {
                if (value) {
                    moment.locale('es')
                    return moment(String(value)).format('LLLL a')
                }
            }
        },
        methods:{
          traeRegistros(){
            var url= 'https://apinba.justcreative.mx/api/lector/traerInvitado';
                  axios.get(url).then(
                        ({data}) => {
                          this.total = data.total;   
                          this.arrayEstado = data.estado;
                        }
                    );
          },
         descargaReporte(){
            this.loader = true;
              this.$fileDownload('https://apinba.justcreative.mx/api/registro/reporteInvitado',
                          "reporte-asistencia-evento-NBA-" +  this.$getDateHour() + ".xlsx",
                  ).then(
                      () => {
                          this.loader = false;
                      }
                  )
          }
        },
        mounted(){
          this.traeRegistros();
        }
  }
</script>

import axios from 'axios'
import bus from "@/utils/bus";
export default (url_file, name_file, loaders_options = false,loaderreporte=false, nameloader="") => {


    if (loaders_options) {

        if (loaders_options.ctx.$refs[loaders_options.refLoader].length > 0) {
            loaders_options
                .ctx
                .$refs[loaders_options.refLoader][0]
                .style
                .display = "inline";
        } else {
            loaders_options
                .ctx
                .$refs[loaders_options.refLoader]
                .style
                .display = "inline";
        }

  }

  if(loaderreporte){
    bus.$emit("open_reporte_loader", nameloader)
  }

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: url_file,
      responseType: 'arraybuffer'
    }).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name_file);
      document.body.appendChild(link);
      link.click();
      if (loaders_options) {

        if (loaders_options.ctx.$refs[loaders_options.refLoader].length > 0) {
          loaders_options.ctx.$refs[loaders_options.refLoader][0].style.display = "none";
        } else {
          loaders_options.ctx.$refs[loaders_options.refLoader].style.display = "none";
        }

        loaders_options.ctx.$refs[loaders_options.refOk][0].style.visibility = "visible";
        setTimeout(() => {
          loaders_options.ctx.$refs[loaders_options.refOk][0].style.visibility = "hidden";
        }, 2000)
      }
      bus.$emit("close_reporte_loader", nameloader)
      resolve(response)
    }, error => {
      reject(error)
    })
  })


}

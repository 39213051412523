<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Procesando respuesta
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
  export default {
    data () {
      return {
        dialog: true,
        sistema:"",
        myCoordinates:{
            lat:0,
            lng:0
        },
        dire:{
            estado:"",
            pais:"",
            ciudad:""
        }
      }
    },
    methods:{
        guardarGatos(){
            this.$getLocation({})
                .then(coordinates => {
                    this.myCoordinates = coordinates;
                    this.usaGeo();
                })
                .catch(error => {
                    console.log(error);
                    this.enviaData();
                  }); 
        },
        usaGeo(){
            var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.myCoordinates.lat + ',' + this.myCoordinates.lng +'&key=AIzaSyDi1peq0RBC6zLi9a8gnfCESmtCEVHBbWE';
            fetch(url)
            .then(response => response.json())
            .then(data => {   
              console.log(data.results[1].address_components);              
                  for (let i = 0; i < data.results[1].address_components.length; i++)
                  {
                        if (data.results[1].address_components[i].types[0] == 'administrative_area_level_1')
                        {
                            this.dire.estado = data.results[1].address_components[i].long_name;
                        }
                        if (data.results[1].address_components[i].types[0] == "country")
                        {
                            this.dire.pais = data.results[1].address_components[i].long_name;
                        }
                        if (data.results[1].address_components[i].types[0] == 'locality')
                        {
                            this.dire.ciudad = data.results[1].address_components[i].long_name;
                        }
                  }

                  this.enviaData();
            });
        },
        enviaData(){          
            axios.post('https://apinba.justcreative.mx/api/lector/guardaQr',{
                'sistema' : this.sistema,
                'estado': this.dire.estado,
                'pais': this.dire.pais,
                'ciudad': this.dire.ciudad

            }).then(function (response) {
                console.log(response);
                  if (navigator.userAgent.indexOf("Win") != -1)
                    {
                        window.location.replace("https://play.google.com/store/apps/details?id=com.nbaimd.gametime.nba2011");
                    }

                    if (navigator.userAgent.indexOf("Mac") != -1)
                    {
                        window.location.replace("https://apps.apple.com/mx/app/nba-app-basketball-y-noticias/id484672289");
                    }

                    if (navigator.userAgent.indexOf("Android") != -1) 
                    {
                       window.location.replace("https://play.google.com/store/apps/details?id=com.nbaimd.gametime.nba2011");
                    }
                    
                    if (navigator.userAgent.indexOf("Mac") != -1 || navigator.userAgent.indexOf("iPhone") != -1 || navigator.userAgent.indexOf("iPod") != -1 || navigator.userAgent.indexOf("iPad") != -1 || navigator.userAgent.indexOf("IOS") != -1)
                    {
                       window.location.replace("https://apps.apple.com/mx/app/nba-app-basketball-y-noticias/id484672289");
                    }

            }).catch(function (error) {
                console.log(error);
            });
        }
    },
    mounted(){    
        if (navigator.userAgent.indexOf("Win") != -1)
        {
            this.sistema = 'Windows';
        }

        if (navigator.userAgent.indexOf("Mac") != -1)
        {
            this.sistema = 'Mac OS';
        }

        if (navigator.userAgent.indexOf("Android") != -1) 
        {
            this.sistema = 'Android';
        }
        
        if (navigator.userAgent.indexOf("Mac") != -1 || navigator.userAgent.indexOf("iPhone") != -1 || navigator.userAgent.indexOf("iPod") != -1 || navigator.userAgent.indexOf("iPad") != -1)
        {
            this.sistema = 'IOS';
        }

                this.enviaData();
    }
  }
</script> 

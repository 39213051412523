<template>
  <div class="text-center mt-5">
    <br>
      <center><img  src="img/logo.png"   style="width: 70px; "></center>
     <br><br> 
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-4 col-10 mt-5">
           <p style="color: #fff; font-size:20px;">Descarga de reporte</p>
           <br>    
           <div class="mt-3">
               <v-btn @click="descargaReporte()" block style="color: #266BC3; text-transform: capitalize;">  Base de datos Formulario NBA <b-spinner small v-if="loader"></b-spinner></v-btn>
            </div>  
            <div class="mt-3">         
                 
            </div>     
          </div>
      </div>
  </div>
</template>
<script>
// import axios from 'axios'
// import Swal from 'sweetalert2'
  export default {
    data () {
      return { 
        loader:false
      }
    },
    methods:{
        descargaReporte(){
           this.loader = true;
            this.$fileDownload('https://apinba.esmarketing.mx/api/registro/reporte',
                        "reporte-registro-NBA-" +  this.$getDateHour() + ".xlsx",
                ).then(
                    () => {
                        this.loader = false;
                    }
                )
        }
    } 
  }
</script> 
<style>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
</style>
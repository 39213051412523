<template>
  <div class="text-center mt-5">
    <br>
      <center><img  src="img/logo.png"   style="width: 70px; "></center>
     <br>    
     <b-form @submit="registraForm">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-4 col-10 mt-5">
           <p style="color: #fff; font-size:20px;">Ingresa los siguientes datos</p>
           <div class="mt-3">
            <input v-model="nombre"  class="form-control text-center" placeholder="Nombre" style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
            <small v-if="errors.includes('nombre')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div>  
            <div class="mt-3">         
                <input v-model="paterno"  class="form-control text-center" placeholder="Apellido Paterno"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                <small v-if="errors.includes('paterno')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div>  
            <div class="mt-3">         
                <input v-model="materno"  class="form-control text-center" placeholder="Apellido Materno (opcional)"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                <small v-if="errors.includes('materno')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div>  
           <div class="mt-3">       
            <input v-maska data-maska="##/##/####" v-model="fecha"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"  class="form-control text-center" placeholder="dd/mm/aaaa">
           <!-- <input type="text" pattern="\d{1,2}/\d{1,2}/\d{4}" class="datepicker" name="date" value="" /> -->
                <!-- <input v-model="fecha" type="text"  class="form-control text-center" 
                size="10" maxlength="10"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"
                 placeholder="dd/mm/aaaa" pattern="\d{1,2}/\d{1,2}/\d{4}" required> -->

                <small v-if="errors.includes('dia')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div>  
            <!-- <div > 
                <div class="row justify-content-center">
                    <div class="col-12 mt-4">
                        <p style="color:#fff;">Fecha Nacimiento</p>
                    </div>
                    <div class="col-4">
                        <input type="number" v-model="dia"  class="form-control text-center" placeholder="Día"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                  
                    </div>
                    <div class="col-4">
                        <input   type="number" v-model="mes" class="form-control text-center" placeholder="Mes"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                  
                    </div>
                   <div class="col-4">
                        <input  type="number" v-model="ano"  class="form-control text-center" placeholder="Año"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
 
                    </div>
                </div>  
                <div class="col-12">
                       <small v-if="errors.includes('dia')" class="text-white mt-2 text-left">
                                <i class="fas fa-exclamation-circle"></i>
                                Este campo es obligatorio
                        </small>
                </div>
            </div>   -->
            <div class="mt-3">         
                <input   class="form-control text-center" v-model="correo" type="email" placeholder="Correo electrónico"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                <small v-if="errors.includes('correo')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div> 
            <div class="mt-3">         
                <input   class="form-control text-center" v-model="estado" placeholder="Estado (opcional)"  style="box-shadow: 0px 2px 5px 0px #393939; color:#266BC3;"> 
                <small v-if="errors.includes('estado')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
            </div> 
            <div class="mt-5 text-center" style="color:#fff;">    
                
                <v-checkbox color="success" v-model="acepto">
                    <template v-slot:label>
                        <div style="color:#fff; font-size:15px;" class="text-center">
                           Consiento que mi información personal se utilice para enviarme mensajes sobre productos y servicios relacionados con la NBA, y compartir mi 
                           información personal con socios y afiliados de la NBA para que también puedan contactarme sobre productos y servicios que pueden ser de mi interés.
                        </div>
                    </template>
                </v-checkbox>
                <small v-if="errors.includes('acepto')" class="text-white mt-2 text-left">
                        <i class="fas fa-exclamation-circle"></i>
                        Este campo es obligatorio
                </small>  
                <!-- <b-form-checkbox class="text-white text-center" style="font-size:12px;"  
                    name="checkbox-1"
                    value="accepted" 
                    size="lg"
                    >
                    Acepto participar: consiento que mi información personal se utilice para enviarme mensajes sobre productos y servicios relacionados con la NBA, y compartir mi 
                    información personal con socios y afiliados de la NBA para que también puedan contactarme sobre productos y servicios que pueden ser de mi interés.
                </b-form-checkbox> -->
            </div>  
            <div class="mt-3 "> 
                <p style="color: #fff; font-size:15px;">Consulta el aviso de privacidad: <a  style="color: #fff; font-size:15px;" href="https://www.nba.com/privacy-policy" target="_blank">https://www.nba.com/privacy-policy</a></p>
            </div> 
            <div class="mt-5"> 
                <br>
                <v-btn type="submit">  Enviar <b-spinner small v-if="loader"></b-spinner></v-btn>
                <br>
                <br>
                <br>
            </div>
          </div>
      </div>
       </b-form>
  </div>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2'
import { vMaska } from "maska"
  export default {
    directives: { maska: vMaska },
    data () {
      return {
        errors:[],
        nombre:"",
        paterno:"",
        materno:"",
        dia:"",
        mes:"",
        ano:"",
        correo:"",
        estado:"",
        fecha:"",
        acepto:false,
        loader:false
      }
    },
    methods:{
        registraForm(e){   
            e.preventDefault();
            this.errors = [];
            // this.fecha = this.dia + '-' + this.mes + '-' + this.ano;
            if(!this.nombre){
                this.errors.push('nombre');  
            }if(!this.paterno){
                this.errors.push('paterno');  
            } if(!this.fecha){
                this.errors.push('dia');  
            }if(!this.correo){
                this.errors.push('correo'); 
            } if(this.acepto == false){
                this.errors.push('acepto'); 
            }
            if(this.errors.length == 0){
                this.loader = true;
                axios.post('http://apiqr.test/api/registro/store',{
                    'nombre' : this.nombre,
                    'paterno': this.paterno,
                    'materno': this.materno,
                    'fecha': this.fecha,
                    'correo': this.correo,
                    'estado': this.estado,
                    'acepto': this.acepto
                }).then(response => {
                    console.log(response);          
                    Swal.fire(
                    "List@",
                    "Se ha enviado exitosamente tu registro.",
                    "success"
                    )                    
                    this.vaciarDatos();    
                }).catch(function (error) {
                    console.log(error);
                });       
            }      
        },
        vaciarDatos(){
            this.nombre = "";
            this.paterno = "";
            this.materno = "";
            this.fecha = ""; 
            this.correo = "";
            this.estado = "";
            this.dia = "";
            this.mes = "";
            this.ano = "";
            this.acepto = false;
            this.loader = false;
        },
    } 
  }
</script> 
<style>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
 
</style>
<template>
  <div class="text-center" v-if="valida != 2">
      <center><img   :src="'https://nba.justcreative.mx/img/' + valida + '.png'"  style="width: 340px; "></center>
  </div>
</template>
<script>
import axios from 'axios'
// import Swal from 'sweetalert2'
  export default {
    data () {
      return {
        valida:2
      }
    },
    methods:{
        enviaData(){   
          var url= 'https://apinba.justcreative.mx/api/lector/invitadoSave?invitado='+ this.$route.params.id;
            axios.get(url).then(
                  ({data}) => {
                     this.valida = data.valida;
                  }
              );              
        }
    },
    mounted(){    
      
        this.enviaData();
    }
  }
</script> 
